
import { defineComponent } from "vue";
import { tokenData } from "../interface/index";
import Error from "@/components/Error.vue";

export default defineComponent({
  name: "Token",
  components: {
    Error,
  },
  data() {
    return {
      tokenData: {
        name: "",
        symbol: "",
        hashingAlgo: "",
        description: "",
        marketCap: 0,
        homepage: "",
        genesis: "",
      } as tokenData,
      notFound: false,
      pageLoadError: undefined as string | undefined,
      pageLoadStatus: 0,
      loading: true,
    };
  },

  async mounted() {
    try {
      const response = await this.axios.get(
        `coins/${this.$route.params.id}?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false`
      );

      this.tokenData.name = response.data.name;
      this.tokenData.symbol = response.data.symbol;
      this.tokenData.hashingAlgo = response.data.hashing_algorithm;
      this.tokenData.description = response.data.description.en;
      this.tokenData.marketCap = response.data.market_data.market_cap.eur;
      this.tokenData.homepage = response.data.links.homepage[0];
      this.tokenData.genesis = response.data.genesis_date;
      this.loading = false;
    } catch (error) {
      this.loading = false;

      this.pageLoadStatus = error.response.status;
      this.pageLoadError = error.response.data.error;
    }
  },
});
